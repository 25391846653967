<template lang="pug">
v-container(fluid)
  v-row(justify="center", align="center")
    .mb-6(style="width: 1000px")
      v-flex#executaveis-table(md12)
        .d-flex.justify-content-between
          v-btn.mt-4(color="black", icon, @click="goBack()", fab, small)
            v-icon fa-arrow-left
          h2.ma-2 Executáveis {{ version }}
        v-tabs(v-model="tab")
          v-tab(key="CS") ControlSoft
          v-tab(key="SYS") SySafra
        v-tabs-items(v-model="tab")
          v-tab-item.pa-1(key="CS")
            v-card(elevation="3")
              v-data-table(
                :headers="headers",
                :items="exeCS",
                :footer-props="footprops",
                :no-data-text="'Nenhum executável ControlSoft encontrado.'",
                :loading="!loaded",
                loading-text="Carregando...",
                hide-default-footer
              )
                template(v-slot:item.url="{ item }")
                  a(:href="item['url']") {{ item["url"] }}
            .d-flex.mt-2.justify-end
              span {{ exeCS.length }} executáveis encontrados

            h3.ma-2 Opcionais
            v-card(elevation="3")
              v-data-table(
                :headers="headers",
                :items="opcCS",
                :footer-props="footprops",
                :no-data-text="'Nenhum executável opcional encontrado.'",
                :loading="!loaded",
                loading-text="Carregando...",
                hide-default-footer
              )
                template(v-slot:item.url="{ item }")
                  a(:href="item['url']") {{ item["url"] }}

            .d-flex.mt-2.justify-end
              span {{ opcCS.length }} executáveis encontrados

          v-tab-item.pa-1(key="SYS")
            v-card(elevation="3")
              v-data-table(
                :headers="headers",
                :items="exeSYS",
                :footer-props="footprops",
                :no-data-text="'Nenhum executável SySafra encontrado.'",
                :loading="!loaded",
                loading-text="Carregando...",
                hide-default-footer
              )
                template(v-slot:item.url="{ item }")
                  a(:href="item['url']") {{ item["url"] }}

            .d-flex.mt-2.justify-end
              span {{ exeSYS.length }} executáveis encontrados

            h3.ma-2 Opcionais
            v-card(elevation="3")
              v-data-table(
                :headers="headers",
                :items="opcSYS",
                :footer-props="footprops",
                :no-data-text="'Nenhum executável opcional encontrado.'",
                :loading="!loaded",
                loading-text="Carregando...",
                hide-default-footer
              )
                template(v-slot:item.url="{ item }")
                  a(:href="item['url']") {{ item["url"] }}

            .d-flex.mt-2.justify-end
              span {{ opcSYS.length }} executáveis encontrados
</template>

<script>
import { mapMutations } from "vuex";
import { versoes as api } from "@/api";

export default {
  name: "releasesVersao",
  data: () => ({
    tab: "CS",
    headers: [
      { value: "name", text: "Nome", width: "280" },
      {
        value: "url",
        text: "URL",
        sortable: false,
      },
    ],
    version: " Caregando...",
    loaded: false,
    exeCS: [],
    exeSYS: [],
    opcCS: [],
    opcSYS: [],
    footprops: {
      itemsPerPageOptions: [30, 50, 100, 200, 300, 500, -1],
      showCurrentPage: true,
      showFirstLastPage: true,
      itemsPerPageAllText: "Todos",
      itemsPerPageText: "Itens por página",
    },
  }),
  methods: {
    ...mapMutations("loader", ["showLoader", "hideLoader"]),
    goBack() {
      this.$router.push({ name: "releases" });
    },

    async getDados() {
      this.showLoader("Carregando Executaveis...");

      try {
        //** Inicio do processo */
        let exe = await api.getExecutaveisExternalVersao(
          this.$router.currentRoute.params.id
        );

        // Adicionar o nome do executável
        exe.forEach((row) => {
          const t = row.url.substring(
            row.url.lastIndexOf("/") + 1,
            row.url.length
          );
          row.name = t;
        });

        // Extrair primeiramente os opcionais
        this.opcCS = exe.filter((x) =>
          x.url.toLowerCase().includes("opcionais")
        );

        this.opcSYS = this.opcCS.filter(
          (x) =>
            x.name.toLowerCase().includes("sy") ||
            x.url.toLowerCase().includes("sys/opcionais")
        );

        // retornar para this.opcCS os itens que não existem em this.opcSYS
        this.opcCS = this.opcCS.filter(
          (x) => !this.opcSYS.some((y) => y.url === x.url)
        );

        // remover os opcionais
        exe = exe.filter((x) => !x.url.toLowerCase().includes("opcionais"));

        // separar os executáveis do SySafra
        this.exeSYS = exe.filter((x) => x.name.toLowerCase().includes("sy"));

        // remover os executáveis do SySafra
        exe = exe.filter((x) => !x.name.toLowerCase().includes("sy"));

        // separar os executáveis do ControlSoft
        this.exeCS = exe;

        // sort em ordem alfabética
        this.opcCS.sort((a, b) => a.name.localeCompare(b.name));
        this.exeSYS.sort((a, b) => a.name.localeCompare(b.name));
        this.exeCS.sort((a, b) => a.name.localeCompare(b.name));

        //** Fim do processo */
      } catch (error) {
        console.error(error);
      } finally {
        this.loaded = true;
        this.hideLoader();
        if (this.exeCS.length > 0) {
          this.version = " - " + this.$router.currentRoute.params.id;
        } else {
          this.version = " - Versão indisponível";
        }
      }
    },
  },
  mounted() {
    this.getDados();
  },
};
</script>