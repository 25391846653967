var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('div',{staticClass:"mb-6",staticStyle:{"width":"1000px"}},[_c('v-flex',{attrs:{"id":"executaveis-table","md12":""}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('v-btn',{staticClass:"mt-4",attrs:{"color":"black","icon":"","fab":"","small":""},on:{"click":function($event){return _vm.goBack()}}},[_c('v-icon',[_vm._v("fa-arrow-left")])],1),_c('h2',{staticClass:"ma-2"},[_vm._v("Executáveis "+_vm._s(_vm.version))])],1),_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:"CS"},[_vm._v("ControlSoft")]),_c('v-tab',{key:"SYS"},[_vm._v("SySafra")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:"CS",staticClass:"pa-1"},[_c('v-card',{attrs:{"elevation":"3"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.exeCS,"footer-props":_vm.footprops,"no-data-text":'Nenhum executável ControlSoft encontrado.',"loading":!_vm.loaded,"loading-text":"Carregando...","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item['url']}},[_vm._v(_vm._s(item["url"]))])]}}])})],1),_c('div',{staticClass:"d-flex mt-2 justify-end"},[_c('span',[_vm._v(_vm._s(_vm.exeCS.length)+" executáveis encontrados")])]),_c('h3',{staticClass:"ma-2"},[_vm._v("Opcionais")]),_c('v-card',{attrs:{"elevation":"3"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.opcCS,"footer-props":_vm.footprops,"no-data-text":'Nenhum executável opcional encontrado.',"loading":!_vm.loaded,"loading-text":"Carregando...","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item['url']}},[_vm._v(_vm._s(item["url"]))])]}}])})],1),_c('div',{staticClass:"d-flex mt-2 justify-end"},[_c('span',[_vm._v(_vm._s(_vm.opcCS.length)+" executáveis encontrados")])])],1),_c('v-tab-item',{key:"SYS",staticClass:"pa-1"},[_c('v-card',{attrs:{"elevation":"3"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.exeSYS,"footer-props":_vm.footprops,"no-data-text":'Nenhum executável SySafra encontrado.',"loading":!_vm.loaded,"loading-text":"Carregando...","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item['url']}},[_vm._v(_vm._s(item["url"]))])]}}])})],1),_c('div',{staticClass:"d-flex mt-2 justify-end"},[_c('span',[_vm._v(_vm._s(_vm.exeSYS.length)+" executáveis encontrados")])]),_c('h3',{staticClass:"ma-2"},[_vm._v("Opcionais")]),_c('v-card',{attrs:{"elevation":"3"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.opcSYS,"footer-props":_vm.footprops,"no-data-text":'Nenhum executável opcional encontrado.',"loading":!_vm.loaded,"loading-text":"Carregando...","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item['url']}},[_vm._v(_vm._s(item["url"]))])]}}])})],1),_c('div',{staticClass:"d-flex mt-2 justify-end"},[_c('span',[_vm._v(_vm._s(_vm.opcSYS.length)+" executáveis encontrados")])])],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }